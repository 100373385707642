import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import TabBar from 'components/TabBar'
import ContactHeader from '../../StaticQueries/ContactHeader'
import SuccessGraphic from '../../StaticQueries/SuccessGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      minWidth: 600,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 936,
    },
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
    // height: 368,
  },
  container: {
    padding: '48px 36px 48px',
  },
})


const SuccessForm = props => (
  <div id="main" className="alt">
    <section id="one">
      <div className="inner">
        <header className="major">
          <h1>Message Successfully Sent.</h1>
        </header>

        <span className="image main">
          <SuccessGraphic/>
        </span>

        <h3>Thank you for contacting us!</h3>
      </div>
    </section>
  </div>
)


function SuccessContent({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= C°O°N°T°A°C°T =-</h1> */}
            <ContactHeader/>
          </AppBar>

          <div className={classes.contentWrapper} style={{color: 'blue'}}>
            {/* <Typography color="textSecondary" align="justify"> */}
						<typography color="textPrimary" align="justify">
              <SuccessForm />
            </typography>
            {/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

SuccessContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SuccessContent)
